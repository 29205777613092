import { callApi, sanitizeArgs } from '../helpers/caller';
import { go404, buildTokenName } from '../helpers';
import { logout, addNotification } from './index';

export const FAILED_REQUEST = 'FAILED_REQUEST';
export const RECEIVED_EVENT_GET = 'RECEIVED_EVENT_GET';
export const REQUEST_SESSIONS_GET = 'REQUEST_SESSIONS_GET';
export const RECEIVED_SESSIONS_GET = 'RECEIVED_SESSIONS_GET';
export const RECEIVED_SESSIONS_GETBYID = 'RECEIVED_SESSIONS_GETBYID';
export const RECEIVED_USER_ADDTOAGENDA = 'RECEIVED_USER_ADDTOAGENDA';
export const RECEIVED_SPEAKERS_GET = 'RECEIVED_SPEAKERS_GET';
export const RECEIVED_SPEAKERS_GETSINGLE = 'RECEIVED_SPEAKERS_GETSINGLE';
export const RECEIVED_HOTELS_LIST = 'RECEIVED_HOTELS_LIST';
export const RECEIVED_PERSONS_LIST = 'RECEIVED_PERSONS_LIST';
export const RECEIVED_SCHEMA_GET = 'RECEIVED_SCHEMA_GET';
export const RECEIVED_ABSTRACTS_LIST = 'RECEIVED_ABSTRACTS_LIST';
export const RECEIVED_ABSTRACTS_MYLIST = 'RECEIVED_ABSTRACTS_MYLIST';
export const RECEIVED_ABSTRACTS_GET = 'RECEIVED_ABSTRACTS_GET';
export const RECEIVED_VIDEOS_GET = 'RECEIVED_VIDEOS_GET';
export const REQUEST_ABSTRACTS_GET = 'REQUEST_ABSTRACTS_GET';
export const REQUEST_BADGE_GET = 'REQUEST_BADGE_GET';
export const REQUEST_GOOGLEWALLETPASS_GET = 'REQUEST_GOOGLEWALLETPASS_GET';
export const REQUEST_BADGEAPPLEWALLET_GET = 'REQUEST_BADGEAPPLEWALLET_GET';
export const RECEIVED_GOOGLEWALLETPASS_GET = 'RECEIVED_GOOGLEWALLETPASS_GET';
export const RECEIVED_BADGEAPPLEWALLET_GET = 'RECEIVED_BADGEAPPLEWALLET_GET';
export const RECEIVED_BADGE_GET = 'RECEIVED_BADGE_GET';
export const RECEIVED_ABSTRACTS_CREATE = 'RECEIVED_ABSTRACTS_CREATE';
export const RECEIVED_USER_NOTIFICATIONS = 'RECEIVED_USER_NOTIFICATIONS';
export const RECEIVED_ROOMS_LIST = 'RECEIVED_ROOMS_LIST';
export const REQUEST_ROOMS_LIST = 'REQUEST_ROOMS_LIST';
export const REQUEST_HOTELS_CREATERESERVATION =
  'REQUEST_HOTELS_CREATERESERVATION';

export const api = (args, quietError) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const policyId = state.api.policy.data.id;
      const eventId = state.api.policy.data.eventId;
      const tokenName = buildTokenName(eventId, policyId);

      const request = sanitizeArgs(args, tokenName);

      if (!request.success) {
        if (request.code === 403) {
          dispatch(logout(true));
        }
        return reject(request.message);
      }

      dispatch(ajaxRequest(request));

      const cached = hasValidCache(getState(), request);

      if (cached) {
        dispatch(ajaxReceivedCached(request, cached));
        resolve(cached);
        return;
      }
      return callApi(request.url, request.params, args.insecure, args.dataType)
        .then((response) => {
          dispatch(ajaxReceived(request, response));
          resolve(response);
        })
        .catch((err) => {
          err.cause = err.cause ?? {};
          //! This is targeted only towards getUser requests, because if a bug exists and 403 returns on a public user the user will lose access.
          if (
            (err.cause === undefined || err.cause.status == 403) &&
            request.params.method === 'GET' &&
            request.args.action === 'get' &&
            request.args.endpoint === 'user'
          ) {
            return dispatch(logout(true));
          }
          if (
            request.args.endpoint === 'policies' &&
            request.args.action === 'get' &&
            request.params.method === 'GET' &&
            err.cause.status === 404
          ) {
            if (request.args.params.policyId) {
              window.location.href =
                window.location.origin + '/' + request.args.params.urlAlias;
              return;
            } else {
              return go404();
            }
          }

          if (
            request.params.method === 'GET' &&
            err.cause.status === 403 &&
            args.insecure
          ) {
            return go404();
          }
          if (
            request.args.endpoint === 'user' &&
            request.params.method === 'GET'
          ) {
            dispatch(ajaxFailed(request, err));
            reject(err);
          } else {
            dispatch(ajaxFailed(request, err));
            if (!quietError) {
              dispatch(addNotification(err.message, 0));
            }
            reject(err);
          }
        });
    });
  };
};

export const ajaxRequest = (request) => {
  return {
    type: `REQUEST_${request.args.endpoint.toUpperCase()}_${request.args.action.toUpperCase()}`,
    endpoint: request.args.endpoint,
    action: request.args.action,
  };
};

export const ajaxReceivedCached = (request, response) => {
  return {
    type: `RECEIVED_CACHED_${request.args.endpoint.toUpperCase()}_${request.args.action.toUpperCase()}`,
    response,
  };
};

export const ajaxReceived = (request, response) => {
  const obj = {
    type: `RECEIVED_${request.args.endpoint.toUpperCase()}_${request.args.action.toUpperCase()}`,
    response,
  };

  if (request.args.hasOwnProperty('extra')) {
    obj.extra = request.args.extra;
  }

  return obj;
};

export const ajaxFailed = (request, err) => {
  return {
    type: FAILED_REQUEST,
    endpoint: request.args.endpoint,
    actionKey: request.args.action,
    errorMessage: err.message,
    status: err.cause ? err.cause.status : 400,
  };
};

const hasValidCache = (state, request) => {
  if (request.args.hasOwnProperty('cache') && request.args.cache === false) {
    return false;
  }

  if (request.params.method === 'GET') {
    if (
      state.api.hasOwnProperty(request.args.endpoint) &&
      state.api[request.args.endpoint].hasOwnProperty(request.args.action)
    ) {
      const matched = state.api[request.args.endpoint][request.args.action];
      if (!matched.needsUpdate) {
        if (
          (matched.data.constructor === Object &&
            Object.keys(matched.data).length) ||
          (matched.data.constructor === Array && matched.data.length)
        ) {
          return matched;
        }
      }
    }
  }

  return false;
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
