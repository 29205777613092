export const initialState = {
  colorPalette: window.colorPalette,
  lastProhibitedUrl: null,
  language: {
    selected: 'en',
  },
  init: {
    isValidUrl: false,
    loading: true,
    url: null,
    path: null,
  },
  policy: {
    data: {},
    ready: false,
    meta: {},
    screen: 'login',
    visibility: {
      password: false,
      confirmPassword: false,
    },
    resetPasswordAvailable: false,
    fetching: false,
  },
  user: {
    ready: false,
    data: {},
    loggedIn: false,
    errorMessage: '',
    fetching: false,
    pseudo: false,
  },
  validateToken: {
    data: {},
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
  },
  verifyEmail: {
    data: {},
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
  },
  event: {
    data: {},
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
  },
  videos: {
    data: {
      search: '',
      roomIds: '',
      speakerIds: '',
    },
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
    showingVideo: {
      active: false,
      sessionId: null,
      speechId: null,
    },
  },
  sessions: {
    data: [],
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
    calendarReady: false,
    calendar: {},
    showingSpeeches: {
      active: false,
      sessionId: null,
    },
  },
  innerSession: {
    data: {},
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
    calendarReady: false,
    calendar: {},
    showingSpeeches: {
      active: false,
      sessionId: null,
    },
  },
  hotels: {
    data: [],
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
    showingHotel: {
      active: false,
      hotelId: null,
    },
  },
  rooms: {
    data: [],
    fetching: true,
  },
  speakers: {
    data: [],
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
    showingSpeaker: {
      active: false,
      speakerId: null,
      data: {},
      ready: false,
    },
  },
  payments: {
    active: false,
    creatingPaymentRecord: false,
    showingPayment: {
      active: false,
      products: {},
    },
  },
  sponsors: {
    active: false,
    showingSponor: {
      active: false,
    },
  },
  ebag: {
    active: false,
    showingEbag: {
      active: false,
    },
  },
  exhibition: {
    data: [],
    meta: {},
    invalid: false,
    ready: false,
    fetching: false,
    showingExhibitor: {
      active: false,
      exhibitorId: null,
      data: {},
      ready: false,
    },
  },
  products: {
    productsTabIndex: 0,
    data: [],
    meta: [],
    ready: false,
    active: false,
    showingProduct: {
      active: false,
    },
  },
  abstracts: {
    data: [],
    meta: {},
    ready: false,
    fetching: false,
    abstractTabIndex: 0,
    showingAbstract: {
      active: false,
      speakerId: null,
      data: {},
      ready: false,
      fetching: false,
    },
    showingAbstractPreview: {
      active: false,
      data: {},
      ready: false,
      fetching: false,
    },
  },
  myAbstracts: {
    data: [],
    meta: {},
    ready: false,
    fetching: false,
  },
  schema: {
    data: {},
    meta: {},
    ready: false,
    fetching: false,
  },
  persons: {
    data: [],
    meta: {},
    ready: false,
    fetching: false,
  },
  counter: null,
  hasUnsavedChanges: false,
  hasRequestedChangeSection: false,
  doingAjax: false,
  freeAccess: false,
  userNotifications: {
    data: {},
  },
  requestThrottle: {
    password: {
      timeout: 0,
      cooldown: 0,
      targetCooldown: 0,
      lastTimestamp: 0,
      iteration: 0,
    },
    register: {
      timeout: 0,
      cooldown: 0,
      targetCooldown: 0,
      lastTimestamp: 0,
      iteration: 0,
    },
  },
  recaptcha: {
    login: {
      token: null,
      failedAttempts: 0,
      valid: false,
    },
    register: {
      token: null,
      failedAttempts: 0,
      valid: false,
    },
    questionnaire: {
      token: null,
      failedAttempts: 0,
      valid: false,
    },
    message: {
      token: null,
      failedAttempts: 0,
      valid: false,
    },
    passwordReset: {
      token: null,
      failedAttempts: 0,
      valid: false,
    },
  },
};
